"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMISSIONS_REDUCED_PER_GALLON_OF_RENEWABLES = exports.EMISSIONS_REDUCED_PER_MILE_NOT_TRAVELED = exports.MISUSE_AND_THEFT_RATE = exports.WEAR_AND_TEAR_PER_MILE_NEW = exports.WEAR_AND_TEAR_PER_MILE = exports.MILES_PER_ROUND_TRIP = exports.DRIVER_HOURLY_WAGE = exports.AVERAGE_TIME_TO_FROM_GAS_STATION = exports.EMPLOYEES_PER_VEHICLE = void 0;
exports.EMPLOYEES_PER_VEHICLE = 1;
exports.AVERAGE_TIME_TO_FROM_GAS_STATION = 20 / 60; // 20 minutes converted to hours
exports.DRIVER_HOURLY_WAGE = 24.31; // dollars
exports.MILES_PER_ROUND_TRIP = 2.2; // miles
exports.WEAR_AND_TEAR_PER_MILE = 0.49;
exports.WEAR_AND_TEAR_PER_MILE_NEW = 0.74;
exports.MISUSE_AND_THEFT_RATE = 3 / 100; // percentage
exports.EMISSIONS_REDUCED_PER_MILE_NOT_TRAVELED = 1.1;
exports.EMISSIONS_REDUCED_PER_GALLON_OF_RENEWABLES = 15.67;
